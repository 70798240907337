@use "/src/styles/" as *;

.district-card {
    cursor: pointer;
    background-color: $gray-20;
    &:first-child {
        border-radius: 12rem 12rem 0 0;
        //overflow: hidden;
        .district-card__img-wrap {
            border-radius: 12rem 0 0 0;
        }
    }
    &:last-child {
        border-radius: 0 0 12rem 12rem;
        //overflow: hidden;
        .district-card__img-wrap {
            border-radius: 0 0 0 12rem;
        }
    }
    &__wrap {
        display: flex;
    }
    &__img-wrap {
        display: block;
        padding: 12rem;
        background-color: $dark-blue;
        height: 70rem;
    }
    &__img {
        display: block;
        height: 100%;
        aspect-ratio: 40 / 50;
    }
    &__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 16rem 0 16rem;
    }
    &__title {
        display: block;
        @include fnt(16, 18, $weight: 600);
        margin-bottom: 2rem;
    }
    &__subtitle {
        display: block;
        @include fnt(12, 16, $text-color-secondary);
    }
    &__back {
        display: flex;
        align-items: center;
        gap: 4rem;
        @include fnt(12, 16, $blue);
        &-arrow {
            display: block;
            flex: none;
            height: 20px;
            width: 20px;
        }
    }
    &.sticky {
        position: sticky;
        top: 0;
    }
    &:hover {
        background-color: $gray-40;
    }
    
}
.microdistrict-list {
    background-color: $gray-20;
}
.microdistrict {
    display: block;
    padding: 8rem 16rem;
    background-color: transparent;
    cursor: pointer;
    &__title {
        display: flex;
        justify-content: space-between;
    }
    &__name {
        @include fnt(16, 18)
    }
    &__count {
        @include fnt(16, 18, $text-color-secondary)
    }
    &:hover {
        background-color: $gray-40;
    }
    &.active {
        background-color: $blue-700;
    }
    &.active &__name {
        color: #fff;
    }
    &.active &__count {
        color: #fff;
    }
}
.landplot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8rem 20rem;
    background-color: $gray-20;
    cursor: pointer;
    &__name {
        display: flex;
        align-items: center;
        gap: 4rem;
        @include fnt(14, 16);
        &:before {
            content: '';
            display: block;
            height: 6rem;
            width: 6rem;
            border-radius: 50%;
            background-color: #66C61C;
        }
    }
    &__count {
        @include fnt(14, 16, $text-color-secondary);
    }
    &:hover {
        background-color: $gray-40;
    }
}
.district-card__hr {
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid rgba(0, 16, 61, 0.12);
}