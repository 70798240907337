@use "../../styles/" as *;

.footer {
    background-color: #046;
    padding: 28rem 32rem;
    &__logos {
        display: flex;
        gap: 32rem;
        justify-content: center;
        flex-wrap: wrap;
    }
    &__phone-wrap {
        display: flex;
        flex-direction: column;
    }
    &__container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 32rem;
    }
    &__phone {
        display: block;
        @include fnt(24, 24, #fff, 600);
        margin-bottom: 0;
        opacity: 1;
        transition: opacity .2s ease-in-out;
        text-decoration: none;
        &:hover {
            opacity: 0.7;
        }
    }
    &__phone-title {
        @include fnt(14, 24, #fff);
    }
}