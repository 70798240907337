@use "../../styles/" as *;

.mapPageContainer {
    width: 100%;
}
.map-container {
    display: grid;
    grid-template-columns: 320rem calc(100% - 342rem);
    //display: flex;
    gap: 16px;
    margin-top: 16rem;
    @include tablet {
        grid-template-columns: 100%;
    }
}

