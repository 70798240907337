@use "../../styles/" as *;

.history {
    display: flex;
    flex-wrap: wrap;
    padding: 4rem 0;
    gap: 4rem;
    border-bottom: 1px solid $border;
    &__date {
        @include fnt(14, 20, $text-color-secondary);
        margin: 0;
    }
    &__text {
        @include fnt(14, 20, $text-color, 600);
        margin: 0;
    }
    &__status {
        @include fnt(14, 20, $text-color);
        margin: 0;
    }
   &:last-child {
    border-bottom: none;
   }
}