@use "../../../styles/" as *;

.form__btn,
.select-item__control--is-disabled,
textarea,
input[type="text"],
input[type="tel"],
input[type="number"],
input[type="email"],
input[type="search"],
input[type="password"] {
  border-style: solid;
  appearance: none;
  &:focus {
    outline: none;
  }
  &:read-only {
    background-color: $gray-20 !important;
  }
}
select:focus {
  outline: none;
}
.date-range-wrap {
  display: flex;
  align-items: center;
  .form__item {
    margin: 0;
  }
  .date-range__hyphen {
    @include fnt(14, 24, $text-gray);
    margin: 0 12rem;
  }
}
.form__item {
  padding: 0;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  height: 100%; 
  margin: 0;

  ._read-only {
    .select-item {
      &__control {
        background-color: $gray-20;
        &:hover {
          cursor: default;
        }
      }
      &__input {
        opacity: 0 !important;
      }
    }
  }

  //               React-select start

  .select-item {
    &__control {
      border: 1px solid rgba(0, 16, 61, 0.12);
      border-radius: 8rem;
      padding: 11rem 15rem;
      @include fnt(14, 20);
      background-color: #fff;
      cursor: pointer;
      width: 100%;
      box-shadow: none;
      &:hover {
        border: 1px solid rgba(0, 16, 61, 0.12);
      }
    }
    &__input-container {
      @include fnt(14, 20);
    }
    &__indicator {
      padding: 0;
      svg {
        display: none;
      }
    }
    &__dropdown-indicator {
      background: url("../../../../public/interface_img/select-arrow.svg") no-repeat center;
      display: block;
      height: 24rem;
      width: 24rem;
      background-size: 16rem;
    }
    &__value-container {
      padding: 0;
    }
    &__single-value {
      margin: 0;
    }
    &__input-container {
      margin: 0;
      padding: 0;
    }
    &__indicator-separator {
      display: none;
      width: 0;
      margin: 0;
    }
    &__multi-value {
      background-color: #fff;
      border: 1px solid $cyan;
      border-radius: 12rem;
      &__label {
        @include fnt(14, 20, $black);
        padding: 0 0 0 8rem;
      }
      &__remove {
        &:hover {
          background-color: transparent;
          color: $red;
        }
      }
    }
  }
  //                   React-select end

  div {
    label {
      @include fnt(14, 24);
    }
  }
  label {
    @include fnt(12, 16, $black, 500);
    margin-left: 12rem;
    margin-bottom: 0;
    a {
      margin: 0 8rem;
    }
    span {
      color: $cyan !important;
      //font-size: 16rem;
      //line-height: 18rem;
      margin-left: 4rem;
      cursor: pointer;
    }
  }
  .select,
  textarea,
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="number"],
  input[type="password"],
  select {
    display: block;
    height: auto;
    border: 1px solid rgba(0, 16, 61, 0.12);
    border-radius: 8rem;
    padding: 11rem 23rem;
    @include fnt(14, 24);
    background-color: #fff;
    
    &:valid,
    &:focus {
      + label {
        // styles label for active input
      }
    }
    &.error {
      border-color: $red !important;
      + label {
        color: $red !important;
      }
    }
    &:disabled {
      background-color: $light;
    }
  }
  .select,
  select {
    padding: 11rem 31rem 11rem 15rem;
    appearance: none;
    //background-image: url("../img/select_arrow.svg");
    background-repeat: no-repeat;
    background-size: 16rem;
    background-position: right 16rem top 50%;
  }
  .select.show {
    border-radius: 8rem 8rem 0 0;
  }
  .form__checkbox {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    &._horizontal {
      flex-direction: row;
    }
    input {
      display: none;
    }
    label {
      //display: flex;
      position: relative;
      @include fnt(14, 24);

      margin: 0 24rem 16rem;
      a {
        margin: 0;
        @include fnt(14, 20, $blue);
        text-decoration: none;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: -24px;
        top: 0;
        flex: none;
        height: 20rem;
        width: 20rem;
        background-image: none;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 16rem;
        box-shadow: inset 0 0 0 2rem $icon-gray;
        border-radius: 4rem;
        margin: 2rem 0 0;
      }
    }
    input:checked + label {
      &:before {
        //background-image:  url('../img/checkmark.svg');
        background-image: url("../../../../public/interface_img/checkmark.svg");
        box-shadow: inset 0 0 0 2rem $main;
        background-color: $main;
      }
    }
    input:checked:disabled + label {
      &:before {
        box-shadow: inset 0 0 0 2rem $icon-gray;
        background-color: $icon-gray;
      }
    }
    &._horizontal {
      label {
        margin-bottom: 0;
      }
    }
  }
  .select-item__input {
     background-color: transparent;
  }
  .form__radio {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    &._horizontal {
      flex-direction: row;
    }
    input {
      display: none;
    }
    label {
      display: flex;
      align-items: center;
      margin-bottom: 16rem;
      margin-left: 0;
      margin-right: 24rem;
      &:last-child {
        margin-bottom: 0;
      }
      &:before {
        content: "";
        display: block;
        flex: none;
        height: 16rem;
        width: 16rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 12rem;
        box-shadow: inset 0 0 0 2rem $icon-gray;
        border-radius: 50%;
        margin: 0 4rem 0 0;
      }
    }
    input:checked + label {
      &:before {
        box-shadow: inset 0 0 0 2rem $main;
        //background: rgb(9,9,121);
        background: radial-gradient(circle, $main 3.6rem, #ffffff 4.2rem);
      }
    }
    input:disabled:checked + label {
      &:before {
        box-shadow: inset 0 0 0 2rem $icon-gray;
        //background: rgb(9,9,121);
        background: radial-gradient(circle, $icon-gray 3.6rem, #ffffff 4.2rem);
      }
    }
    &._horizontal {
      label {
        margin-bottom: 0;
      }
    }
  }
}
.error-message {
  @include fnt(12, 16, $red);
  display: block;
  min-height: 16rem;
  margin: 0 12rem;
}
.form__btn {
  display: none;
}
.form__error {
  @include fnt(14, 20, $red);
  display: block;
  margin: 0 0 16rem;
}
.form__label {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  @include fnt(24, 32);
  margin: 0;
  padding: 0;
  cursor: pointer;
  transition: font-variation-settings 0.3s ease-out;
  &:before {
    content: "";
    display: block;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M5 14L11 8L5 2' stroke='%232C2D2E' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    height: 16rem;
    width: 16rem;
    background-size: 16rem;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 8rem;
    transition: transform 0.3s ease-in-out;
  }
  &:hover {
    font-variation-settings: "wght" 500;
    &:before {
      transform: translateX(8rem);
    }
  }
}

.file__item.document-template {
  display: flex;
  align-items: center;
}
.file__item {
  display: flex;
  flex-direction: column-reverse;
  height: fit-content;
  &_wrap {
    display: flex;
    &:before {
      content: "";
      display: block;
      flex: none;
      height: 24rem;
      width: 24rem;
      background: url("../../../../public/interface_img/file.svg") no-repeat center;
      background-size: contain;
      margin: 0 8rem 0 0;
    }
  }
  input {
    display: none;
  }
  .bold-title {
    @include fnt(16, 24, $black, 600);
    margin-left: 0;
  }
  .add-file__btn {
    background-color: $gray-80;
    border-radius: 16rem;
    padding: 4rem 16rem;
    @include fnt(14, 24);
    width: unset;
    text-align: center;
    margin-left: 0;
    margin-top: 8rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    &:hover {
      background-color: $icon-gray;
    }
    &._disabled {
      background-color: $gray-60;
      color: $black;
      pointer-events: none;
    }
  }
  .form__item {
    margin-bottom: 0;
  }
  .file-label {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    &:hover {
      color: $black;
      text-decoration: underline;
    }
  }
  .delete-file {
    display: block;
    height: 24rem;
    width: 24rem;
    flex: none;
    background: url("../../../../public/interface_img/delete.svg") no-repeat center;
  }
}
