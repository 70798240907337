@use "../../styles/" as *;

.cabinet-info {
    display:  flex;
    gap: 8rem;
    @include tablet {
        flex-direction: column;
    }
}
.cabinet-btn {
    display: block;
    @include fnt(14, 24);
    width: fit-content;
    margin: 0;
    padding: 3rem 7rem;
    border: 1px solid $border;
    border-radius: 8rem;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.2s;
    &:hover {
        background-color: $gray-20;
    }
}
.user-info,
.contracts {
    border-radius: 24rem;
    background-color: $gray-40;
    padding: 24rem;
    &__title {
        @include fnt(20, 24);
    }
    &__sub-title {
        display: block;
        @include fnt(14, 20, $weight: 600);
    }

    &__text {
        display: block;
        max-width: 520rem;
        @include fnt(16, 20);
    }
}
.user-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-image: url("../../../public/img/house.png");
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 40%;
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 8rem;
        @include mobile {
            flex-direction: column-reverse;
        }
    }
    &__name {
        @include fnt(24, 32, $weight: 600);
    }
}
.contracts {
    width: 416rem;
    @include tablet {
        width: unset;
    }
    &__text {
        display: flex;
        gap: 4rem;
        cursor: pointer;
        &:after {
            content: "";
            display: block;
            height: 24rem;
            width: 24rem;
            align-items: center;
            background: url(../../../public/interface_img/file-download-blue.svg) no-repeat center;
        }
    }
}
