@use "/src/styles/" as *;

.DetailsText {
    margin: 0 0 8rem 20rem;
    &__title {
        display: block;
        @include fnt(14, 20, $blue);
        cursor: pointer;
        text-decoration: none;
        
    }
    &__text {
        white-space: pre-wrap;
    }
}
