@font-face {
  font-family: "Golos";
  src:
    local("Golos"),
    url("../../public/fonts/Golos/Golos-Text_Regular.woff2") format("woff2"),
    url("../../public/fonts/Golos/Golos-Text_Regular.woff") format("woff");
  font-style: normal;
}
@font-face {
  font-family: "Golos";
  src: local("Golos"), url("../../public/fonts/Golos/GolosText-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Golos";
  src:
    local("Golos"),
    url("../../public/fonts/Golos/Golos-Text_DemiBold.woff2") format("woff2"),
    url("../../public/fonts/Golos/Golos-Text_DemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
