@use "../../styles/" as *;

.go-back {
  display: inline-flex;
  gap: 8rem;
  align-items: center;
  @include fnt(14, 24, $black, 500);
  padding: 12rem 24rem;
  border: 1px solid $border;
  border-radius: 26rem;
  cursor: pointer;
  text-decoration: none;
  width: fit-content;
  transition: border .2s;
  &::before {
    content: "";
    background: url("../../assets/icons/arrow-narrow-left.svg") no-repeat center;
    display: block;
    height: 24rem;
    width: 24rem;
  }
  &:hover {
    border: 1px solid $light-main;
  }
}
.header {
  padding: 0;
  &__top {
    padding: 16px 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
  }
  &__logo {
    &-link {
      display: flex;
      align-items: flex-end;
      gap: 12px;
      text-decoration: none;
    }
    &-img {
      display: block;
      height: 32px;
      width: auto;
    }
    &-text {
      @include fnt(12, 14, $text-color-secondary);
      margin: 0;
    }
  }
  &__info {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
    gap: 12px;
  }
  &__phone {
    display: flex;
    align-items: center;
    @include fnt(20, 24, $text-color, 600);
    transition: color 0.2s ease-in-out;
    text-decoration: none;
    &::before {
      content: "";
      display: block;
      height: 8px;
      width: 8px;
      background-color: $cyan;
      border-radius: 4px;
      margin-right: 8px;
      margin-top: 2px;
    }
    &:hover {
      color: $blue;
    }
  }
  &__login {
    display: block;
    background-color: $blue;
    border-radius: 8px;
    @include fnt(14, 24, #fff);
    padding: 8px 16px;
    transition: background-color 0.2s ease-in-out;
    text-decoration: none;
    &:hover {
      color: #fff;
      background-color: $blue-700;
    }
  }
  &__logout {
    display: block;
    background-color: $gray-40;
    border-radius: 8px;
    @include fnt(14, 24, #ed0a34);
    padding: 8px 16px;
    transition: background-color 0.2s ease-in-out;
    text-decoration: none;
    &:hover {
      color: #ed0a34;
      background-color: $gray-60;
    }
  }
  &__social {
    &-list {
      display: flex;
      gap: 4px;
    }
    display: block;
    position: relative;
    height: 40px;
    width: 40px;
    background-color: $gray-40;
    border-radius: 8px;
    overflow: hidden;
    &:before {
      content: "";
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }
    &:after {
      content: "";
      display: block;
      height: 20px;
      width: 20px;
      background-repeat: no-repeat;
      background-size: 20px;
      margin: 10px;
      filter: brightness(0.76);
      transition: filter 0.2s ease-in-out;
    }

    &._vk {
      &:after {
        background: url("../../assets/icons/socials/vk.svg") no-repeat center;
      }
      &:before {
        background: #2787f5;
      }
    }
    &._odnoklassniki {
      &:after {
        background: url("../../assets/icons/socials/ok.svg") no-repeat center;
      }
      &:before {
        background: #ee8208;
      }
    }
    &._telegram {
      &:after {
        background: url("../../assets/icons/socials/telegram.svg") no-repeat center;
      }
      &:before {
        background: linear-gradient(203.21deg, #37aee2 21.68%, #1e96c8 69.99%);
      }
    }
    &._facebook {
      content: "f";
    }
    &._youtube {
      content: "YT";
    }
    &._instagram {
      content: "i";
    }
    &._twitter {
      content: "t";
    }
    &._rutube {
      &:before {
        background: #14191f;
      }
      &:after {
        background: url("../../assets/icons/socials/rutube.svg") no-repeat center;
      }
    }
    &:hover {
      &:after {
        filter: brightness(1);
      }
      &:before {
        opacity: 1;
      }
    }
  }
}

.header__menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8rem;
  margin-bottom: 16rem;
}
.menu-container {
  display: flex;
  align-items: center;
  gap: 8rem;
  .menu-item {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 8rem 12rem 8rem 8rem;
    border: 1px solid $border;
    border-radius: 8rem;
    text-decoration: none;
    @include fnt(14, 20);
    transition: border .2s;
    &__icon {
      height: 20px;
      width: 20px;
      stroke: $icon-gray;
      transition: stroke .2s;
    }
    &:hover {
      border: 1px solid $light-main;
      .menu-item__icon {
        stroke: $light-main;
        svg {
          stroke: $light-main !important;
        }
      }
    }
  }
}
