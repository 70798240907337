
@use "/src/styles" as *;

.loader_container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 32rem;
    box-sizing: border-box;
  }
  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid $icon-gray;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }