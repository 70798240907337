@use "../../../styles" as *;

.mapContainer{
    //height: calc( 100vh - 160rem); 
    width: 100%;
    position: relative;
    aspect-ratio: 4 / 3;
}

.mapStyle{
    width: 100%;
    height: 100%;
    overflow: hidden;
        border-radius: 12rem;
        border: 1px solid $border;
}

.buttonsPenContainer{
    background-color: $white;
    position: absolute;
    top: 12rem;
    right: 12rem;
    padding: 12rem;
    box-shadow: 0rem 4rem 16rem 0rem rgba(0, 16, 61, 0.16);
    border-radius: 12rem;

    .buttonPen{
        border: 1rem solid rgba(0, 16, 61, 0.12);
        background-color: $gray-20;
        padding: 8rem !important;
        transition: .3s linear;
        cursor: pointer;

        &.active{
            background-color: $blue;
        }
    
        object{
            margin: 0;
            pointer-events: none;
            cursor: pointer;
        }
    }

}

.buttonsNavMap{
    position: absolute;
    right: 16rem;
    bottom: 40rem;
    display: flex;
    gap: 8rem;

    .saveMapButton{
        width: 160rem;
        height: 32rem;
        background-color: $green;
    }
    .closeMapButton{
        width: 160rem;
        height: 32rem;
        background-color: $white;
        outline: 1rem  solid rgba(0, 16, 61, 0.12);
        color: $black;
    }
}

.poligon{
    pointer-events: none;
}

.map-error {
    display: flex;
    padding: 16px 0;
    @include fnt(16, 32);
}