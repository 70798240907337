@use "/src/styles/" as *;

.buy-land {
    &__container {
        display: grid;
        grid-template-columns: 3fr 2fr;
        grid-template-rows: auto 1fr;
        grid-template-areas:
            "info form"
            "map form";
        grid-gap: 16rem 32rem;
        margin-top: 16rem;

        @include tablet {
            grid-template-columns: 100%;
            grid-template-areas:
                "info"
                "form"
                "map";
        }

        .cost {
            &__title {
                @include fnt(14, 20, $text-gray);
            }
            &__dotted {
                display: block;
                height: 8rem;
                background-image: radial-gradient(#bfc1c7 20%, transparent 20%);
                background-size: 8rem;
                flex-grow: 1;
            }
            &__cost {
                @include fnt(32, 32);
            }
        }
    }
    &__land-cost {
        display: flex;
        align-items: end;
        gap: 8rem;
    }
    &__info {
        grid-area: info;
        display: flex;
        flex-direction: column;
        background-color: $gray-40;
        padding: 16rem;
        border-radius: 12rem;
        
    }
    &__cadastral {
        @include fnt(16, 24, $text-color-secondary);
    }
    &__municipality {
        @include fnt(24, 32, $text-color, 600);
        @include mobile {
            @include fnt(20, 24, $weight: 600);
        }
    }
    &__address {
        @include fnt(24, 32);
        @include mobile {
            @include fnt(20, 24);
        }
    }
    &__area {
        @include fnt(20, 32);
        background-color: #fff;
        display: block;
        width: fit-content;
        margin-top: 8px;
        padding: 0 12rem;
        border-radius: 16rem;
    }
    &__map {
        grid-area: map;
        overflow: hidden;
        border-radius: 12rem;
        border: 1px solid $border;
        height: fit-content;
        @include tablet {
            display: none;
        }
    }
    &__form {
        grid-area: form;
        border-radius: 12rem;
        padding: 16rem 24rem;
        background: linear-gradient(180deg, #f3f3f5 0%, rgba(243, 243, 245, 0) 49.9%);
        .form {
            &__title {
                display: block;
                @include fnt(24, 26, $text-color, 600);
                margin-bottom: 8rem;
                @include mobile {
                    @include fnt(20, 24, $weight: 600);
                }
            }
            &__description {
                display: block;
                @include fnt(14, 20);
                margin-bottom: 8rem;
            }
        }
    }
}

.back-to-map {
    display: flex;
    @include fnt(12, 20, $blue);
    text-decoration: none;
    cursor: pointer;
    gap: 4rem;
    svg {
        display: block;
        width: 20rem;
        height: 20rem;
    }
}