@use "/src/styles/" as *;

.map-dropdown {
    display: flex;
    justify-content: space-between;
    border: 1px solid $border;
    border-radius: 8rem;
    padding: 12rem 16rem;
    img {
        transform: rotate(0);
        transition: transform 0.2s;
    }

    &__drop {
        position: absolute;
        top: calc(100% + 4rem);
        border: 1rem solid $border;
        border-radius: 4rem;
        background-color: $gray-20;
        overflow: auto;
        @include scroll();
        max-height: 224rem;
        width: 100%;
        z-index: 3;
    }
    &._active img {
        transform: rotate(180deg);
    }
}
.map-dropdown__wrap {
    position: relative;
}
