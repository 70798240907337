@import "_fonts";
$font: 'Golos', sans-serif;
$black: #2C2D2E;
$white: #fff;
$light: #F3F3F5;
$gray-20: #F9F9FA;
$gray-40: #F3F3F5;
$gray-60: #ECEDF0;
$gray-80: #E6E7EB;
$icon-gray: #BFC1C7;
$text-gray: #919399;
$text-color: $black;
$text-color-secondary: $text-gray;
$border: rgba(0, 16, 61, 0.12);
$red: #ED0A34;
$green: #0DC268;
$cyan: #00CCCC;
$blue: #0088CC;
$blue-700: #005999;
$dark-blue: #004466;
$main: $blue;
$dark-main: $dark-blue;
$light-main: #0095e0;
$page_icons: 'incoming', 'outgoing', 'my-incoming',
'my-outgoing', 'counterparty', 'users', 'register', 'agreement',
'service-records','my-service-records', 'my-agreement';
//$light-main: $main + #111;
$gradient: linear-gradient(90deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
$gradient-reverse: linear-gradient(90deg, #007ED9 0%, #007FD9 6.67%, #0081D8 13.33%, #0085D8 20%, #008AD7 26.67%, #0090D6 33.33%, #0098D5 40%, #00A1D3 46.67%, #00AAD2 53.33%, #00B2D0 60%, #00BACF 66.67%, #00C1CE 73.33%, #00C6CD 80%, #00C9CC 86.67%, #00CBCC 93.33%, #0CC 100%);
$mobile-width: 600px;
$tablet-width: 1024px; // еще есть в карте
$laptop-width: 1440px;
$hd-width: 1920px;