@use '/src/styles/' as *;

.modal {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    display: none;
    place-items: center;
    background-color: rgba(0, 0, 0, .4);
    pointer-events: none;
    opacity: 0;
    transition: opacity .3s;
    overflow: auto;
    z-index: 1000;
    padding: 32rem;
    @include tablet() {
        padding: 16rem;
    }
    &__content {
      background-color: #fff;
      border-radius: 24rem;
      padding: 24rem;
      margin: 0 auto 0 auto;
      min-width: 360rem;
      max-width: 100%;
      width: fit-content;
      width: 600rem;
      &._small{
        width: 420rem;
      }
      &._lightbox {
        padding: 0;
        min-width: unset;
        overflow: hidden;
        border-radius: 4rem;
        img {
          max-width: 100%;
        }
      }
    }
    &.active {
      opacity: 1;
      pointer-events: all;
    }
  
    &__buttons-panel {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 8rem;
      align-items: center;
      margin: 24rem 0;
      * {
        justify-self: end;
      }
    }
  }
  .modal.inventory .modal__content{
    min-width: unset;
  }