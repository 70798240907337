@use "../../styles/" as *;

.service-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 8rem;
    @include tablet {
        grid-template-columns: repeat(3, 1fr);
    }
    @include mobile {
        grid-template-columns: 1fr;
    }
}
.history-container {
    display: flex;
    flex-direction: column;
    max-width: 848rem;
}

.cabinet__sub-title {
    @include fnt(24, 32, $weight: 600);
}
