@use "../../styles/" as *;

.form-container {
    @include mobile {
        width: 100%;
    }
}
.form-card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 32rem 64rem 40rem 64rem;
    border-radius: 16rem;
    margin-top: 64rem;
    box-shadow: 0rem 12rem 32rem 0rem rgba(0, 0, 0, 0.10);
    width: 432rem;
    max-width: 100%;
    @include mobile {
        width: unset;
        margin: 32rem 16rem 0;
        padding: 16rem 32rem 24rem 32rem;
    }
    &__title {
        @include fnt(32, 40, $weight: 600);
        margin-bottom: 0;
    }
    &__title-24 {
        @include fnt(24, 32, $weight: 600);
        margin-bottom: 0;
    }
    &__title-20 {
        @include fnt(20, 24, $weight: 600);
        margin-bottom: 0;
    }
    &__title-16 {
        @include fnt(16, 24, $weight: 600);
        margin-bottom: 0;
    }
    &__description {
        @include fnt(14, 20, $text-gray);
        margin-bottom: 16rem;
    }
}
.form-btn {
    @include btn()
}
.second-form-btn {
    @include btn($bg-color: $cyan)
}