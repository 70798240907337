@use "../../styles/" as *;

.feedback {
    display: flex;
    background: $gradient-reverse;
    justify-content: center;
    align-items: center;
    gap: 16rem;
    padding: 8rem;
    border-radius: 12rem;
    &__text {
        @include fnt(16, 24, #fff);
        margin: 0;
    }
    &__btn {
        @include fnt(14, 24);
        margin: 0;
        padding: 3rem 15rem;
        border: 1px solid $border;
        background-color: #fff;
        border-radius: 8rem;
        text-decoration: none;
        cursor: pointer;
        transition: background-color 0.2s;
        &:hover {
            background-color: $gray-20;
        }
    }
    @include tablet {
        flex-direction: column;
        &__text {
            text-align: center;
        }
    }
}
