@use "../../styles/" as *;

.service-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 24rem;
    background-color: $gray-40;
    padding: 24rem;
    aspect-ratio: 256 / 160;
    cursor: pointer;
    transition: background-color .2s ease-in-out;
    &__title {
        @include fnt(14, 20, $weight: 600);
        margin: 0;
        transition: color .2s ease-in-out;
    }
    &__icon {
        display: block;
        height: 24rem;
        width: 24rem;
        transition: filter .2s ease-in-out;
    }
    @include mobile {
        flex-direction: row;
        aspect-ratio: unset;
      }
    &:hover {
        background-color: $main;
        .service-card__title {
            color: #fff;
        }
        .service-card__icon {
            filter: grayscale(1) brightness(3);
        }
    }
}