@import './variables';

@mixin fnt($size: 16, $height: 24, $color: $black, $weight: 400, $font: $font) {
  font-family: $font;
  font-style: normal;
  font-weight: $weight;
  font-size: #{$size}rem;
  line-height: #{$height}rem;
  color: $color;
}

@mixin btn($bg-color: $main, $color: #fff) {
  @include fnt(16, 24, $color !important, 600);
  display: flex;
  padding: 12rem 24rem;
  background-color: $bg-color;
  border-radius: 8rem;
  width: fit-content;
  transition:
    background-color 0.2s ease-out,
    color 0.2s ease-out;
    cursor: pointer;
  border: none;
  &:hover {
    text-decoration: none;
    color: $color !important;
    background-color: darken($bg-color, 5%);
  }
  &._block {
    width: unset;
    justify-content: center;
  }
  &._disabled {
    pointer-events: none;
    opacity: .2;
  }
}

@mixin gradient-text () {
  background: linear-gradient(135deg, #00CCCC 0%, #00CBCC 6.67%, #00CACC 13.33%, #00C6CC 20%, #00C2CC 26.67%, #00BCCC 33.33%, #00B5CC 40%, #00AECC 46.67%, #00A6CC 53.33%, #009FCC 60%, #0098CC 66.67%, #0092CC 73.33%, #008ECC 80%, #008ACC 86.67%, #0089CC 93.33%, #0088CC 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

@mixin scroll() {
  margin-right: -6rem;
  &::-webkit-scrollbar {
      width: 4rem;
      
  }
  &::-webkit-scrollbar-track {
      background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
      border-radius: 4rem;
      background-color: $icon-gray;
  }
  &::-webkit-scrollbar-button {
      height: 4rem;
  }
  scrollbar-gutter: auto;
}