@use "../../../styles/" as *;

.district-list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: $gray-20;
    border-radius: 12rem;
    //height: calc( 100vh - 160rem);
    width: 100%;
    aspect-ratio: 320 / 704;
    @include scroll();

    @include tablet {
        border: 1px solid $border;
        height: 400rem;
    }
}