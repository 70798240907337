@use "./styles" as *;

* {
  box-sizing: border-box;
}
html {
  font-size: 1px;
  //font-size: clamp(1px, calc(100 * 100vw / 1920 / 100), 1.33px);
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  // @include hd {
  //   width: calc((100 * 1920 / 100));
  // }
  // @include laptop {
  //   width: calc((100 * 1440 / 100));
  // }
  // @include tablet {
  //   width: calc((100 * 1024 / 100));
  // }
  // @include mobile {
  //   width: calc((100 * 375 / 100));
  // }
}

p,
h1,
h2,
h3,
h4,
span {
  margin: 0;
}

.page-title {
  @include fnt(32, 48, $weight: 600);
  @include mobile {
    @include fnt(24, 32, $weight: 600);
  }
}

.form-help-text {
  @include fnt(14, 24);

  margin: 0 0 16rem;
  a {
    margin: 0;
    @include fnt(14, 20, $blue);
    text-decoration: none;
  }
}

hr {
  width: 100%;
  border: none;
  border-bottom: 1px solid $border;
  margin: 12rem 0;
}
.d-none {
  display: none;
}
.container {
  margin: 0 auto;
  width: clamp(320rem, 100%, 1312rem);
  padding: 0 16rem;
  min-height: 100%;
  box-sizing: border-box;
}

body {
  @include fnt();
  margin: 0;
}
#cabinet {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 108rem;
}

.bg-gray {
  background-color: $gray-20;
}

._bold {
  font-weight: 600;
}

$indents: 0, 2, 4, 8, 12, 14, 16, 18, 20, 22, 24, 32, 40, 48, 54;
.m {
  @each $margin in $indents {
    &-#{$margin} {
      margin: #{$margin}rem !important;
    }
    &t-#{$margin} {
      margin-top: #{$margin}rem !important;
    }
    &b-#{$margin} {
      margin-bottom: #{$margin}rem !important;
    }
    &l-#{$margin} {
      margin-left: #{$margin}rem !important;
    }
    &r-#{$margin} {
      margin-right: #{$margin}rem !important;
    }
    &y-#{$margin} {
      margin-top: #{$margin}rem !important;
      margin-bottom: #{$margin}rem !important;
    }
    &x-#{$margin} {
      margin-right: #{$margin}rem !important;
      margin-left: #{$margin}rem !important;
    }
  }
}
.p {
  @each $padding in $indents {
    &-#{$padding} {
      padding: #{$padding}rem !important;
    }
    &t-#{$padding} {
      padding-top: #{$padding}rem !important;
    }
    &b-#{$padding} {
      padding-bottom: #{$padding}rem !important;
    }
    &l-#{$padding} {
      padding-left: #{$padding}rem !important;
    }
    &r-#{$padding} {
      padding-right: #{$padding}rem !important;
    }
    &y-#{$padding} {
      padding-top: #{$padding}rem !important;
      padding-bottom: #{$padding}rem !important;
    }
    &x-#{$padding} {
      padding-right: #{$padding}rem !important;
      padding-left: #{$padding}rem !important;
    }
  }
}

.success {
  &__title {
    @include fnt(32, 38, $black, 600);
    @include gradient-text();
  }
  &__description {
    @include fnt(16, 24);
    margin-top: 8rem;
  }
  &__subtitle {
    @include fnt(16, 24, $text-gray);
    margin-top: 12rem;
  }
  &__btn {
    @include fnt(16, 24, #fff, 600);
    display: block;
    background-color: $blue;
    padding: 12rem 24rem;
    border: none;
    border-radius: 8rem;
    margin-top: 12rem;
    transition: background-color 0.2s;
    cursor: pointer;
    &:hover {
      background-color: $blue-700;
    }
  }
}
